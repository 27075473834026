import { Col, Row } from 'antd'
import { theme } from 'theme'

const InstallationForm: React.FC = () => {
  return (
    <Col>
      <p className="heading-03-bold">{'Commissioning Checklist'}</p>
      <p className="heading-06-regular">
        {
          'To complete this checklist, ensure that the EV chargers and communication hardware are installed and powered on.'
        }
      </p>
      <p className="heading-06-regular">{'Check for green/blue flashing lights on devices.'}</p>
      {[
        'https://swtch-garden-bucket.s3.us-east-1.amazonaws.com/installation-example/IMG_8369.png',
        'https://swtch-garden-bucket.s3.us-east-1.amazonaws.com/installation-example/WiFi+Access+Point+is+ON+example.png',
        'https://swtch-garden-bucket.s3.us-east-1.amazonaws.com/installation-example/LTE+is+ON+example.png',
      ].map((image, index) => (
        <img style={{ width: '100%', marginBottom: 32 }} alt={`Installation instruction ${index}`} src={image} />
      ))}
      <p className="heading-05-bold" style={{ margin: '16px 0px' }}>
        {'What you will need'}
      </p>
      {[
        'Charger serial number',
        'Charger ID',
        'Photos of the installed charger',
        'Panel and breaker information',
        'Photos of the panel',
      ].map((option) => (
        <Row align="middle">
          <div style={{ margin: '0px 8px', width: 5, height: 5, borderRadius: 5, backgroundColor: 'black' }} />
          <p className="heading-06-regular" style={{ marginBottom: 0 }}>
            {option}
          </p>
        </Row>
      ))}
      <p className="paragraph-01-regular" style={{ marginTop: 32, color: theme.colors.darkGray }}>
        {
          'For the SWTCH team to set up the site correctly, we require accurate information and has passed ESA inspection. This information is crucial to prevent any operational issues and/or damage to the building.'
        }
      </p>
    </Col>
  )
}

export default InstallationForm
