import { Form } from 'antd'
import ChargersListForm from 'components/form/projects/ChargersListForm'
import InstallationForm from 'components/form/projects/InstallationForm'
import NetworkForm from 'components/form/projects/NetworkForm'
import PanelListForm from 'components/form/projects/PanelListForm'
import SummaryForm from 'components/form/projects/SummaryForm'
import { useApiCall } from 'hooks/useApiCall'
import { useNotifications } from 'hooks/useNotifications'
import { ApiError } from 'models/error'
import {
  SelfServeChargerConnectivitySchema,
  SelfServeInstallationDetailSchema,
  SelfServeInstallationSchema,
  SelfServeNetworkSchema,
  SelfServeNetworkSchemaForm,
  SelfServePanelChargerSchema,
  SelfServePanelSchema,
  SelfServePanelSchemaResponse,
  SelfServeStatusType,
  SelfServeUserFormData,
} from 'models/self-serve-commissioning'
import { useEffect, useState } from 'react'
import {
  CreateInstallation,
  CreateOrGetSite,
  CreateOrUpdateUserInfo,
  GetChargerConnectivity,
  GetInstallationDetail,
  GetInstallations,
  GetUserInfo,
  SubmitInstallation,
  UpdateInstallation,
} from 'services/data-provider/self-serve-commissioning'
import { useAppState } from 'state'

const useProjects = (showProfile: boolean, setShowProfile: (val: boolean) => void) => {
  const { currentUser } = useAppState()

  const { openSuccessNotification } = useNotifications()

  const { handleApiCall } = useApiCall()

  const [infoModalClosable, setInfoModalClosable] = useState(false)

  const [openNewSite, setOpenNewSite] = useState(false)

  const [formLoading, setFormLoading] = useState(false)
  const [error, setError] = useState<ApiError>()

  const [infoForm] = Form.useForm()

  const [infoFormInitialValues, setInfoFormInitialValues] = useState<SelfServeUserFormData>({
    name:
      currentUser?.given_name && currentUser?.family_name ? `${currentUser.given_name} ${currentUser.family_name}` : '',
    company: '',
    email: currentUser?.email || '',
    phone: '',
  })

  const [installations, setInstallations] = useState<SelfServeInstallationSchema[]>([])

  const [networkForm] = Form.useForm()
  const [openContactSwtchform, setOpenContactSwtchForm] = useState(false)
  const [openCompletedForm, setOpenCompletedForm] = useState(false)
  const [connectivity, setConnectivity] = useState<SelfServeChargerConnectivitySchema[]>([])

  const [currentStep, setCurrentStep] = useState(0)
  const [userInputNetworkFields, setUserInputNetworkFields] = useState<SelfServeNetworkSchemaForm>()

  const networkFieldsRequired: (keyof SelfServeNetworkSchemaForm)[] = ['site', 'type', 'pin', 'router']

  const [selectedInstallation, setSelectedInstallation] = useState<SelfServeInstallationDetailSchema>()

  const progress = currentStep * 25

  useEffect(() => {
    getUserInfo()
  }, [])

  useEffect(() => {
    if (!openNewSite && infoModalClosable) {
      loadInstallations()
      setSelectedInstallation(undefined)
      setCurrentStep(0)
      setUserInputNetworkFields(undefined)
      setConnectivity([])
    }
  }, [openNewSite, infoModalClosable])

  const handleInfoModal = () => setShowProfile(!showProfile)
  const handleNewSite = () => setOpenNewSite(!openNewSite)

  const loadInstallations = async () => {
    await handleApiCall(
      async () => {
        const { data } = await GetInstallations()
        if (data) setInstallations(data)
      },
      setFormLoading,
      setError,
    )
  }

  const getUserInfo = async () => {
    await handleApiCall(
      async () => {
        const { data } = await GetUserInfo()
        if (!data) handleInfoModal()
        else {
          const { firstName, lastName, ...rest } = data
          setInfoFormInitialValues({
            ...rest,
            name: `${firstName} ${lastName}`,
          })
          setInfoModalClosable(true)
        }
      },
      setFormLoading,
      setError,
    )
  }

  const onFinishInfoModal = async (values: SelfServeUserFormData) => {
    await handleApiCall(
      async () => {
        const { data } = await CreateOrUpdateUserInfo({ ...infoFormInitialValues, ...values })
        const { firstName, lastName, ...rest } = data
        setInfoFormInitialValues({
          ...rest,
          name: `${firstName} ${lastName}`,
        })
        handleInfoModal()
        setInfoModalClosable(true)
      },
      setFormLoading,
      setError,
    )
  }

  const handleCardClick = async (item: SelfServeInstallationSchema) => {
    const response = await selectInstallationDetail(item.id)

    if (response?.chargers) {
      if (response?.status === 'in_progress') {
        setCurrentStep(3)
      } else {
        setCurrentStep(4)
      }
    } else if (response?.panels) {
      setCurrentStep(3)
    } else if (response?.networks) {
      setCurrentStep(2)
    } else {
      setCurrentStep(1)
    }

    handleNewSite()
  }

  const handleOpenContactForm = () => setOpenContactSwtchForm(!openContactSwtchform)
  const handleOpenCompletedForm = () => setOpenCompletedForm(!openCompletedForm)

  const goToNextStep = () => setCurrentStep(currentStep + 1)
  const goToPrevStep = () => setCurrentStep(currentStep - 1)

  const handleSummaryConfirm = () => {
    const assistanceRequired = selectedInstallation?.status === 'assistance_required'
    if (assistanceRequired) {
      handleOpenContactForm()
    } else {
      handleOpenCompletedForm()
    }
  }

  const handleContactForm = () => {
    const assistanceRequired = selectedInstallation?.status === 'assistance_required'
    if (assistanceRequired) {
      handleOpenContactForm()
      handleNewSite()
    } else {
      submitInstallation('assistance_required', handleOpenContactForm)()
    }
  }

  const createInstallationForm = async () => {
    await handleApiCall(
      async () => {
        const { data } = await CreateInstallation()
        setSelectedInstallation(data)
        goToNextStep()
      },
      setFormLoading,
      setError,
    )
  }

  const selectInstallationDetail = async (id: string): Promise<SelfServeInstallationDetailSchema> => {
    return await handleApiCall(
      async () => {
        const { data } = await GetInstallationDetail(id)
        setSelectedInstallation(data)
        return data
      },
      setFormLoading,
      setError,
    )
  }

  const getChargerConnectivity = async (serialNumbers: string[]) => {
    return await handleApiCall(
      async () => {
        const { data } = await GetChargerConnectivity(serialNumbers)
        let updatedConnectivity = [...connectivity]
        if (serialNumbers.length === 1) {
          const replacableConnectivityIndex = updatedConnectivity.findIndex(
            ({ serialNumber }) => serialNumber === serialNumbers[0],
          )

          if (replacableConnectivityIndex > -1) {
            updatedConnectivity.splice(replacableConnectivityIndex, 1, data[0])
          } else {
            updatedConnectivity.push(data[0])
          }
        } else {
          updatedConnectivity = [...data]
        }
        setConnectivity(updatedConnectivity)
      },
      setFormLoading,
      setError,
    )
  }

  const checkConnectivity = (serialNumbers: string[]) => async () => {
    const hasWeakConnectivity = connectivity.some(({ connectivityStatus }) => connectivityStatus === 'weak')

    if (connectivity.length === 0) {
      getChargerConnectivity(serialNumbers)
    } else if (hasWeakConnectivity) {
      handleOpenContactForm()
    } else {
      goToNextStep()
    }
  }

  const areObjectsEqual = (obj1: any, obj2: any): boolean => {
    // Check if both are objects
    if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
      return obj1 === obj2
    }

    // Get keys from both objects
    const keys1 = Object.keys(obj1)
    const keys2 = Object.keys(obj2)

    // Ensure all keys in obj2 are in obj1 (we only compare obj2 keys)
    for (const key of keys2) {
      if (!keys1.includes(key)) {
        return false // Key exists in obj2 but not in obj1
      }
      // Recursively compare values
      if (!areObjectsEqual(obj1[key], obj2[key])) {
        return false
      }
    }

    return true
  }

  // Wrapper function to compare arrays
  const areArraysEqual = (arr1: any[] | undefined, arr2: any[]): boolean => {
    if (arr1?.length !== arr2.length) return false

    for (let i = 0; i < arr1.length; i++) {
      if (!areObjectsEqual(arr1[i], arr2[i])) {
        return false
      }
    }

    return true
  }

  const updateInstallation = async (
    request: SelfServeNetworkSchema[] | SelfServePanelSchema[] | SelfServePanelChargerSchema[],
    callBack?: () => void,
  ) => {
    await handleApiCall(
      async () => {
        if (!selectedInstallation || !selectedInstallation?.site) return
        const type = formSteps[currentStep]?.field as 'networks' | 'panels' | 'chargers'

        const shouldUpdate = !areArraysEqual(selectedInstallation[type], request)
        if (shouldUpdate) {
          const { data } = await UpdateInstallation({
            id: selectedInstallation?.id,
            site: {
              id: selectedInstallation?.site?.id,
            },
            [type]: request,
          })
          setSelectedInstallation(data)
        }

        callBack && callBack()
      },
      setFormLoading,
      setError,
    )
  }

  const submitInstallation = (status: SelfServeStatusType, callBack: () => void) => async () => {
    await handleApiCall(
      async () => {
        if (!selectedInstallation || !selectedInstallation?.id) return
        await SubmitInstallation(selectedInstallation?.id, status)
        if (status === 'complete') {
          openSuccessNotification('Installation Completed!')
        }
        callBack()
        handleNewSite()
      },
      setFormLoading,
      setError,
    )
  }

  const createOrGetSite = async (address: string) => {
    let siteId = ''
    await handleApiCall(
      async () => {
        if (selectedInstallation) {
          const { data } = await CreateOrGetSite(selectedInstallation.id, address)
          setSelectedInstallation(data)
          siteId = data?.site?.id || ''
        }
      },
      setFormLoading,
      setError,
    )
    return siteId
  }

  const areNetworkFieldsFilled = (userInputs?: SelfServeNetworkSchemaForm) => {
    return networkFieldsRequired.every((field) => userInputs?.[field] !== undefined)
  }

  const doPanelsHaveBreakers = (panels: SelfServePanelSchemaResponse[]) => {
    return panels?.every((panel) => panel?.breakers?.length)
  }

  const disableSubmitNewSite = (field: 'networks' | 'panels' | 'chargers') => {
    switch (field) {
      case 'networks':
        return !areNetworkFieldsFilled(userInputNetworkFields)
      case 'panels':
        return !(selectedInstallation?.panels && doPanelsHaveBreakers(selectedInstallation.panels))
      case 'chargers':
        return !selectedInstallation?.chargers
      default:
        return false
    }
  }

  const formSteps = [
    {
      saveTitle: 'Start',
      components: <InstallationForm />,
      onFinish: createInstallationForm,
    },
    {
      field: 'networks',
      saveTitle: 'Next:Panel',
      cancelText: 'Back',
      onFinish: networkForm.submit,
      disableSubmit: disableSubmitNewSite('networks'),
      components: (
        <NetworkForm
          form={networkForm}
          goToNextStep={goToNextStep}
          initialValues={selectedInstallation?.networks?.[0]}
          initialSiteInfo={selectedInstallation?.site}
          createOrGetSite={createOrGetSite}
          updateInstallation={updateInstallation}
          setUserInputNetworkFields={setUserInputNetworkFields}
        />
      ),
    },
    {
      field: 'panels',
      saveTitle: 'Next:Chargers',
      cancelText: 'Back',
      onCancel: goToPrevStep,
      onFinish: goToNextStep,
      disableSubmit: disableSubmitNewSite('panels'),
      components: (
        <PanelListForm initialValues={selectedInstallation?.panels} updateInstallation={updateInstallation} />
      ),
    },
    {
      field: 'chargers',
      saveTitle:
        connectivity.length === 0
          ? 'Check connectivity'
          : connectivity.some(({ connectivityStatus }) => connectivityStatus === 'weak')
            ? 'Contact SWTCH'
            : 'Next: Summary',
      cancelText: 'Back',
      onCancel: goToPrevStep,
      onFinish: checkConnectivity(selectedInstallation?.chargers?.map(({ serialNumber }) => serialNumber || '') || []),
      disableSubmit: disableSubmitNewSite('chargers'),
      components: (
        <ChargersListForm
          connectivity={connectivity}
          getChargerConnectivity={getChargerConnectivity}
          panels={selectedInstallation?.panels}
          initialValues={selectedInstallation?.chargers}
          updateInstallation={updateInstallation}
        />
      ),
    },
    {
      saveTitle: selectedInstallation?.status === 'assistance_required' ? 'Contact SWTCH' : 'Submit',
      cancelText: 'Back',
      onCancel: selectedInstallation?.status === 'assistance_required' ? undefined : goToPrevStep,
      onFinish: handleSummaryConfirm,
      components: (
        <SummaryForm
          currentValues={selectedInstallation as SelfServeInstallationDetailSchema}
          goToStep={setCurrentStep}
        />
      ),
    },
  ]

  return {
    error,
    infoModalClosable,
    installations,
    selectedInstallation,
    currentStep,
    formLoading,
    openNewSite,
    infoForm,
    infoFormInitialValues,
    openContactSwtchform,
    openCompletedForm,
    progress,
    onFinishInfoModal,
    handleNewSite,
    handleInfoModal,
    handleCardClick,
    handleContactForm,
    handleOpenContactForm,
    handleOpenCompletedForm,
    submitInstallation,
    formSteps,
  }
}

export default useProjects
