import { NetworkFormProps } from 'components/form/projects/NetworkForm'
import { SelfServeImageType, SelfServeNetworkSchemaForm, SelfServePhotoSchema } from 'models/self-serve-commissioning'
import { useEffect, useState } from 'react'

const useNetworkForm = ({
  form,
  initialValues,
  initialSiteInfo,
  createOrGetSite,
  updateInstallation,
  setUserInputNetworkFields,
  goToNextStep,
}: NetworkFormProps) => {
  const typeOfNetworks = [
    { value: 'swtch', label: 'SWTCH Communication Hardware' },
    { value: 'building_network', label: 'Building Network' },
    { value: 'cellular', label: 'Cellular' },
    { value: 'non_networked', label: 'Non-networked' },
  ]

  const [openTypeOfNetworkHelp, setOpenTypeOfNetworkHelp] = useState(false)
  const [openGuideLines, setOpenGuideLines] = useState(false)

  const [currentInitialValues, setCurrentInitialValues] = useState<SelfServeNetworkSchemaForm>()
  const [initialValuesLoading, setInitialValuesLoading] = useState(true)

  const infoData = [
    {
      header: 'SWTCH Communication Hardware',
      body: 'You have successfully set up an LTE modem, PoE switch, and wireless access points.',
    },
    {
      header: 'Building Network',
      body: `The chargers are connected to the building's internet via WiFi or Ethernet cable.`,
    },
    {
      header: 'Cellular',
      body: 'The charger has a SIM card inserted and is using it to communicate.',
    },
    {
      header: 'Non-networked Unit ',
      body: 'The charger is a basic plug-and-play unit without any communication features.',
    },
  ]

  const photoGuidelines = [
    {
      image: 'https://swtch-garden-bucket.s3.us-east-1.amazonaws.com/installation-example/LTE+location+example+2.jpeg',
      alt: 'Photo guide line image 1',
      body: 'Capture a clear picture of all communication hardware components (cellular modem, PoE switch) that clearly shows its installation location. Ensure the modem displays the PIN number, and that the PoE switch is in a lockable enclosure.',
    },
    {
      image: 'https://swtch-garden-bucket.s3.us-east-1.amazonaws.com/installation-example/LTE+location+example.jpeg',
      alt: 'Photo guide line image 2',
    },
    {
      image: 'https://swtch-garden-bucket.s3.us-east-1.amazonaws.com/installation-example/Network3.jpeg',
      alt: 'Photo guide line image 2',
    },
    {
      image: 'https://swtch-garden-bucket.s3.us-east-1.amazonaws.com/installation-example/Network4.jpeg',
      alt: 'Photo guide line image 2',
    },
  ]

  useEffect(() => {
    let loadedInitialValues = {}
    if (initialValues) {
      loadedInitialValues = {
        ...initialValues,
        router: initialValues?.photos?.filter((photo) => photo?.type === 'router'),
      }
    }
    if (initialSiteInfo) {
      loadedInitialValues = {
        ...loadedInitialValues,
        site: initialSiteInfo?.address,
      }
    }
    setUserInputNetworkFields(loadedInitialValues)
    setCurrentInitialValues(loadedInitialValues)
    setInitialValuesLoading(false)
  }, [initialValues, initialSiteInfo])

  useEffect(() => {
    if (!initialValuesLoading) {
      //Set the form's fields into the initial state
      form.resetFields()
    }
  }, [initialValuesLoading])

  const handleTypeOfNetworkHelp = () => setOpenTypeOfNetworkHelp(!openTypeOfNetworkHelp)
  const handleGuideLines = () => setOpenGuideLines(!openGuideLines)

  const handleSelect = async (site: string) => {
    if (site) {
      await createOrGetSite(site)
    }
    form.setFieldsValue({ site })
    handleValuesChange(undefined, { ...currentInitialValues, site })
  }

  const handleImageUpload = (type: SelfServeImageType) => (images: SelfServePhotoSchema[]) => {
    form.setFieldsValue({ [type]: images })
    handleValuesChange(undefined, { ...currentInitialValues, [type]: images })
  }

  const handleValuesChange = (_: any, allValues: SelfServeNetworkSchemaForm) => {
    const id = currentInitialValues?.id
    setCurrentInitialValues({ id, ...allValues })
    setUserInputNetworkFields({ id, ...allValues })
  }

  const onFinish = (values: SelfServeNetworkSchemaForm) => {
    const { router, site, ...rest } = values
    const id = currentInitialValues?.id
    updateInstallation(
      [
        {
          id,
          ...rest,
          photos: [...(router || [])],
        },
      ],
      goToNextStep,
    )
  }

  return {
    typeOfNetworks,
    openTypeOfNetworkHelp,
    openGuideLines,
    initialValuesLoading,
    currentInitialValues,
    photoGuidelines,
    infoData,
    handleTypeOfNetworkHelp,
    handleGuideLines,
    handleSelect,
    handleImageUpload,
    handleValuesChange,
    onFinish,
  }
}

export default useNetworkForm
