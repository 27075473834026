import { Form, FormInstance, Input, Radio, Row } from 'antd'
import { SelfServeRadioGroupWrapper } from 'atom/projects/radio-group'
import { styleDisplay } from 'atom/style/display'
import { noSpecialCharacters, onlyNumbers, requiredRule } from 'helpers/rules'
import useAddBreakerForm from 'hooks/projects/useAddBreakerForm'
import { SelfServeBreakerSchema } from 'models/self-serve-commissioning'

export interface AddBreakerFormProps {
  form: FormInstance<any>
  handleModalOpen: () => void
  initialValues?: SelfServeBreakerSchema
  updateInstallation: (request: SelfServeBreakerSchema, callBack?: () => void) => void
}

const AddBreakerForm: React.FC<AddBreakerFormProps> = ({
  form,
  handleModalOpen,
  initialValues,
  updateInstallation,
}) => {
  const { currentInitialValues, typeOfConnectionPhase, handleValuesChange, onFinish } = useAddBreakerForm({
    form,
    handleModalOpen,
    initialValues,
    updateInstallation,
  })

  return (
    <Form
      form={form}
      onValuesChange={handleValuesChange}
      layout="vertical"
      requiredMark={false}
      name="breaker-form"
      onFinish={onFinish}
      initialValues={currentInitialValues}
    >
      <Row justify="space-between">
        <div style={{ ...styleDisplay(47), flexDirection: 'column' }}>
          <Form.Item
            name="breakerNumber"
            style={{ marginBottom: 10 }}
            label={'Breaker Number'}
            rules={[requiredRule('Breaker Number')]}
          >
            <Input placeholder="Breaker Number" />
          </Form.Item>
          <span className="helper">{'Example: 12, 14'}</span>
        </div>
        <Form.Item
          name="ratedCapacity"
          label={'Breaker Size (A)'}
          rules={[requiredRule('Breaker Number'), noSpecialCharacters, onlyNumbers]}
          style={{ ...styleDisplay(47), marginBottom: 10 }}
        >
          <Input type="number" placeholder="Breaker Size (A)" />
        </Form.Item>
      </Row>
      <SelfServeRadioGroupWrapper style={{ marginTop: 10 }}>
        <Form.Item
          name="connectionPhase"
          label={'Connection Phase'}
          rules={[requiredRule('Connection Phase')]}
          style={styleDisplay(100)}
        >
          <Radio.Group>
            {typeOfConnectionPhase.map(({ value, label }) => (
              <Radio key={value} value={value}>
                {label}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>
      </SelfServeRadioGroupWrapper>
    </Form>
  )
}

export default AddBreakerForm
