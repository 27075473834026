import { AddChargerFormProps } from 'components/form/projects/AddChargerForm'
import { SelfServePanelChargerSchema, SelfServePhotoSchema } from 'models/self-serve-commissioning'
import { useEffect, useState } from 'react'

const useAddChargerForm = ({
  form,
  handleModalOpen,
  initialValues,
  updateInstallation,
}: Omit<AddChargerFormProps, 'panels'>) => {
  const [currentInitialValues, setCurrentInitialValues] = useState<SelfServePanelChargerSchema>()
  const [initialValuesLoading, setInitialValuesLoading] = useState(true)

  const [openScanner, setOpenScanner] = useState(false)
  const [openTextScanner, setOpenTextScanner] = useState(false)

  useEffect(() => {
    let loadedInitialValues = {}
    if (initialValues) {
      const { connectors, ...rest } = initialValues

      loadedInitialValues = {
        ...rest,
        listingTitle: connectors?.[0]?.listingTitle,
      }
    }
    setCurrentInitialValues(loadedInitialValues)
    setInitialValuesLoading(false)
  }, [initialValues])

  useEffect(() => {
    if (!initialValuesLoading) {
      form.resetFields()
    }
  }, [initialValuesLoading])

  const handleScanner = () => setOpenScanner(!openScanner)
  const handleTextScanner = () => setOpenTextScanner(!openTextScanner)

  const handleSerialNumberTextRecognition = (text: string) => {
    form.setFieldsValue({ serialNumber: text })
    setOpenTextScanner(!openTextScanner)
  }

  const handleValuesChange = (_: any, allValues: SelfServePanelChargerSchema) => {
    setCurrentInitialValues(allValues)
  }

  const handleImageUpload = (photos: SelfServePhotoSchema[]) => {
    form.setFieldsValue({ photos })
    handleValuesChange(undefined, { ...currentInitialValues, photos })
  }

  const onFinish = (values: SelfServePanelChargerSchema & { listingTitle: string }) => {
    const { listingTitle, ...rest } = values
    const connectors = [
      {
        listingTitle,
        connectorId: 1,
      },
    ]
    const updatedValues = {
      ...rest,
      connectors,
    }
    updateInstallation(updatedValues, initialValues, handleModalOpen)
  }

  const qrCodeScan = (_: any, result: { getText: () => string } | undefined) => {
    if (result) {
      const url = result?.getText()
      const parsedUrl = new URL(url)
      // Extract the value of the "value" query parameter
      const value = parsedUrl.searchParams.get('value')
      form.setFieldsValue({ listingTitle: value })
      handleScanner()
    }
  }

  return {
    currentInitialValues,
    openScanner,
    openTextScanner,
    handleScanner,
    handleTextScanner,
    qrCodeScan,
    setOpenTextScanner,
    handleSerialNumberTextRecognition,
    handleValuesChange,
    handleImageUpload,
    onFinish,
  }
}

export default useAddChargerForm
