import { WebAuth } from 'auth0-js'
import { HttpError, SwtchApiError, SwtchClientError, SwtchError } from '../../models/error'
import { clearSession, getRailsToken, getSession } from '../../state/session-store'

import { Links, Paths } from '../../components/routes/paths'
import configProvider from '../../config'
import {
  buildApiBaseUrl,
  buildApiLoadMgmtBaseUrl,
  buildApiLoadMgmtChargerBaseUrl,
  buildAppUrl,
  buildApiRailsBaseUrl,
  buildApiCihBaseUrl,
} from '../../helpers/url'
import { log } from '../../logger'

/**
 * A generic convenience wrapper around fetch() that will automatically:
 *  * log what's happening;
 *  * add 'Content-Type: json' when no headers are given; and
 *  * deserialize responses with the given type
 *
 * @param url
 * @param options
 * @returns
 */
export async function httpClient<T>(url: string, options: RequestInit): Promise<T> {
  const auth0 = new WebAuth({
    clientID: configProvider.config.auth0.clientId,
    domain: configProvider.config.auth0.domain,
    redirectUri: buildAppUrl(Paths.auth0Callback),
    responseType: 'token id_token',
    scope: configProvider.config.auth0.scope,
    audience: configProvider.config.auth0.audience,
  })

  if (options.method && options.method !== 'GET')
    options.headers = options.headers || new Headers({ 'Content-Type': 'application/json' })

  try {
    log(
      'Executing request [%s %s](headers: %o, body: %s)',
      options.method || 'get',
      url,
      options.headers,
      options.body || '',
    )

    const response = await fetch(url, options)

    log(
      'Received response [%s %s %s](headers: %o)',
      response.status,
      response.statusText,
      response.url,
      response.headers,
    )

    if (!response.ok) {
      if (response.status === 401) {
        log('should logout')
        clearSession()
        auth0.logout({})
        window.location.replace(Links.auth0login())
      } else if (response.status === 403) {
        log('Session Expired')
        localStorage.setItem('signatureExpired', 'true')
      } else if (response.status === 400) {
        log('bad request')
        throw await responseBadRequest(response)
      } else if (response.status === 504) {
        log('Request Timed Out')
        throw await responseBadRequest(response)
      } else {
        throw await responseToSwtchError(response)
      }
    } else {
      localStorage.setItem('signatureExpired', 'false')
    }
    log('Turning response body into response result')
    return await responseToResult<T>(response)
  } catch (error) {
    throw new SwtchClientError('Unable to perform HTTP request correctly', error as Error)
  }
}

async function genericApiClient<T>(baseUrl: string, relativeUrl: string, options: RequestInit): Promise<T> {
  const session = getSession()

  let headerOptions = !options.headers
    ? {
        'Content-Type': 'application/json',
      }
    : options.headers

  headerOptions = {
    ...headerOptions,
    api_key: `${configProvider.config.apiKey}`,
    Authorization: `Bearer ${session?.accessToken}`,
  }

  options.headers = new Headers(headerOptions)

  const absoluteUrl = `${baseUrl}${relativeUrl}`

  try {
    return await httpClient<T>(absoluteUrl, options)
  } catch (error) {
    if (error instanceof HttpError) {
      throw await SwtchApiError.fromHttpError(error)
    } else throw error
  }
}

async function genericRailsApiClient<T>(baseUrl: string, relativeUrl: string, options: RequestInit): Promise<T> {
  let headerOptions = !options.headers ? { 'Content-Type': 'application/json' } : options.headers

  const railsToken = getRailsToken()
  if (railsToken) {
    log('Setting authorization token for manage API request for [%s].', relativeUrl)

    headerOptions = {
      ...headerOptions,
      Authorization: `Bearer ${railsToken}`,
    }
  }

  options.headers = new Headers(headerOptions)

  const absoluteUrl = `${baseUrl}${relativeUrl}`

  try {
    return await httpClient<T>(absoluteUrl, options)
  } catch (error) {
    if (error instanceof HttpError) {
      throw await SwtchApiError.fromHttpError(error)
    } else throw error
  }
}

export async function apiClient<T>(relativeUrl: string, options: RequestInit): Promise<T> {
  return genericApiClient(buildApiBaseUrl(), relativeUrl, options)
}

export async function authApiClient<T>(relativeUrl: string, options: RequestInit): Promise<T> {
  return genericApiClient(`https://${configProvider.config.railsApiDomain}/api/v2/auth`, relativeUrl, options)
}

export async function apiLoadMgmtClient<T>(relativeUrl: string, options: RequestInit): Promise<T> {
  return genericApiClient(`${buildApiLoadMgmtBaseUrl()}`, relativeUrl, options)
}

export async function apiLoadMgmtChargerClient<T>(relativeUrl: string, options: RequestInit): Promise<T> {
  const absoluteUrl = `${buildApiLoadMgmtChargerBaseUrl()}${relativeUrl}`
  return httpClient(absoluteUrl, options)
}

export async function apiRailsClient<T>(relativeUrl: string, options: RequestInit): Promise<T> {
  return genericRailsApiClient(buildApiRailsBaseUrl(), relativeUrl, options)
}

export async function apiCihClient<T>(relativeUrl: string, options: RequestInit): Promise<T> {
  return genericApiClient(buildApiCihBaseUrl(), relativeUrl, options)
}

/**
 *
 * Create a SwtchError object representing an unexpected HTTP response, not
 * necessarily from the SWTCH API.
 *
 * @param response
 * @returns
 */
async function responseToSwtchError(response: Response): Promise<SwtchError> {
  try {
    const result = await response.json()
    throw new SwtchError(result.details)
  } catch (error) {
    if (error instanceof SwtchError) {
      throw error
    }
    throw new SwtchError(
      `An unexpected response was received from a back-end service. Status code: ${response.status} (${response.statusText}); URL ${response.url}`,
    )
  }
}

async function responseBadRequest(response: Response): Promise<SwtchError> {
  const result = await response.json()
  if (response.status === 504) {
    throw new SwtchError('Request Timed Out')
  }
  throw new SwtchError(result.details)
}

async function responseToResult<T>(response: Response): Promise<T> {
  try {
    return await response.json()
  } catch (error) {
    throw new SwtchClientError('The returned body should have been a valid JSON object', error as Error)
  }
}
