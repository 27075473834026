import { EditButtonWrapper, SecondaryDataWrapper, SvgSpacing } from '../../atom/panel-overview'
import moment from 'moment'
import { ButtonLink } from '../../atom/button'
import { editSVG as EditSvg } from 'assets/svg/edit'
import { Links } from '../../components/routes/paths'
import { AllLoadManagements, CTClamp, Instance, Panel } from '../../models/circuit'

export const panelOverviewColumns = (canUpdateSwtchControl: boolean | undefined) => {
  return [
    {
      title: 'Tenant ID',
      dataIndex: 'tenant_id',
    },
    {
      title: 'Tenant Name',
      dataIndex: 'tenant_name',
    },
    {
      title: 'Panel Type',
      dataIndex: 'panel_type',
    },
    {
      title: 'Panel',
      dataIndex: 'panel',
      render: (panel: Panel) => {
        return (
          <>
            <div>{panel.name}</div>
            <SecondaryDataWrapper>ID: {panel.id}</SecondaryDataWrapper>
          </>
        )
      },
    },
    {
      title: 'Exi. Load',
      dataIndex: 'existing_load',
      render: (existing_load: number) => (existing_load > 0 ? existing_load : '-'),
    },
    {
      title: 'Total EVSE',
      dataIndex: 'total_evse',
    },
    {
      title: 'CT Clamp',
      dataIndex: 'ct_clamp',
      render: (ct_clamp: CTClamp) => {
        if (ct_clamp.ip_address === '') return '-'

        return (
          <>
            <div>
              {ct_clamp.ip_address}.{ct_clamp.port}
            </div>
            <SecondaryDataWrapper>ID: {ct_clamp.id}</SecondaryDataWrapper>
          </>
        )
      },
    },
    {
      title: 'Instance',
      dataIndex: 'instance',
      render: (instance: Instance) => {
        if (instance.instanceIp === '' || instance.instancePort === '') return '-'

        return (
          <>
            <div>{instance.instanceNumber}</div>
            <SecondaryDataWrapper>
              {instance.instanceIp}:{instance.instancePort}
            </SecondaryDataWrapper>
          </>
        )
      },
    },
    {
      title: 'Updated By',
      key: 'updated_by',
      sorter: (a: AllLoadManagements, b: AllLoadManagements) =>
        moment(a.updated_by.date).unix() - moment(b.updated_by.date).unix(),
      defaultSortOrder: 'descend',
      render: (panel: AllLoadManagements) => {
        if (panel.updated_by.name && panel.updated_by.date) {
          return (
            <>
              <div>{panel.updated_by.name}</div>
              <SecondaryDataWrapper>{moment(panel.updated_by.date).format('LL')}</SecondaryDataWrapper>
            </>
          )
        }
      },
    },
    {
      title: '',
      key: 'action',
      render: (panel: AllLoadManagements) => {
        return (
          <EditButtonWrapper>
            {canUpdateSwtchControl ? (
              <>
                <ButtonLink
                  icon={
                    <SvgSpacing>
                      <EditSvg />
                    </SvgSpacing>
                  }
                  url={Links.tenant({ selectedTenantId: panel.tenant_id })}
                  hash={panel.panel.id}
                />
              </>
            ) : (
              <></>
            )}
          </EditButtonWrapper>
        )
      },
    },
  ] as any
}
