import { AddPanelFormProps } from 'components/form/projects/AddPanelForm'
import { SelfServePanelSchema, SelfServePhotoSchema } from 'models/self-serve-commissioning'
import { useEffect, useState } from 'react'

const useAddPanelForm = ({ form, handleModalOpen, initialValues, updateInstallation }: AddPanelFormProps) => {
  const [currentInitialValues, setCurrentInitialValues] = useState<SelfServePanelSchema>()
  const [initialValuesLoading, setInitialValuesLoading] = useState(true)

  const [openPanelTypeHelp, setOpenPanelTypeHelp] = useState(false)
  const [openGuideLines, setOpenGuideLines] = useState(false)

  const infoData = [
    {
      header: 'Dedicated Panel',
      body: 'The panel exclusively powers SWTCH EV chargers, with no other loads connected.',
    },
    {
      header: 'Mixed Panel',
      body: `The panel powers EV chargers along with other loads such as HVAC, lighting, or non-SWTCH chargers.`,
    },
  ]

  const photoGuidelines = [
    {
      image: 'https://swtch-garden-bucket.s3.us-east-1.amazonaws.com/installation-example/Electrical+panel+example.jpg',
      alt: 'Photo guide line image 1',
      body: 'Capture a clear picture of the panel. Ensure the panel labels are also captured.',
    },
    {
      image: 'https://swtch-garden-bucket.s3.us-east-1.amazonaws.com/installation-example/Panel+Picture+1.jpg',
      alt: 'Photo guide line image 2',
    },
  ]

  const typeOfPanel = [
    { value: 'Dedicated', label: 'Dedicated Panel' },
    { value: 'Mixed', label: 'Mixed Panel' },
  ]

  useEffect(() => {
    let loadedInitialValues = {}
    if (initialValues) {
      loadedInitialValues = {
        ...initialValues,
      }
    }
    setCurrentInitialValues(loadedInitialValues)
    setInitialValuesLoading(false)
  }, [initialValues])

  useEffect(() => {
    if (!initialValuesLoading) {
      form.resetFields()
    }
  }, [initialValuesLoading])

  const handlePanelTypeHelp = () => setOpenPanelTypeHelp(!openPanelTypeHelp)
  const handleGuideLines = () => setOpenGuideLines(!openGuideLines)

  const handleValuesChange = (_: any, allValues: SelfServePanelSchema) => {
    setCurrentInitialValues(allValues)
  }

  const handleImageUpload = (photos: SelfServePhotoSchema[]) => {
    form.setFieldsValue({ photos })
    handleValuesChange(undefined, { ...currentInitialValues, photos })
  }

  const onFinish = (values: SelfServePanelSchema) => {
    updateInstallation(values, initialValues, handleModalOpen)
  }

  return {
    currentInitialValues,
    typeOfPanel,
    infoData,
    openPanelTypeHelp,
    photoGuidelines,
    openGuideLines,
    handleGuideLines,
    handlePanelTypeHelp,
    handleValuesChange,
    handleImageUpload,
    onFinish,
  }
}

export default useAddPanelForm
