import { Col } from 'antd'

const ContactSwtchForm: React.FC = () => {
  return (
    <Col>
      <p className="heading-03-bold">{'Contact SWTCH for Assistance'}</p>
      <p className="heading-06-regular">{'For support, please reach out to +1 844-798-2438 Ext. 3.'}</p>
    </Col>
  )
}

export default ContactSwtchForm
