import { Auth0DecodedHash } from 'auth0-js'
import { buildApiBaseUrl } from '../../helpers/url'
import { log } from '../../logger'
import { JWTIssue, JWTIssuev2 } from '../../models/auth'
import { UserBase, UserSession } from '../../models/user'
import { mockAdminJwt, mockAdminJwt2, mockSelf } from './mock'
import configProvider from '../../config'
import { getSession } from '../../state/session-store'
import jwt_decode from 'jwt-decode'
import { JWTIdToken } from '../../models/auth'
import { authApiClient } from './client'

export async function JWTFromAuth0(idToken: string): Promise<JWTIssue> {
  const url = `https://${configProvider.config.railsApiDomain}/api/v2/auth`
  log('fetching user auth', { url: url })

  const body = { idToken: idToken }
  log('getting JWT original via auth0', { url: url, body: body })

  if (configProvider.config.mockEnabled) {
    return Promise.resolve(mockAdminJwt)
  }

  return authApiClient('', {
    method: 'POST',
    body: JSON.stringify(body),
  })
}

export async function GetSelf(): Promise<UserBase> {
  const url = '/users/self'
  log('getting self', { url: url })

  if (configProvider.config.mockEnabled) {
    return Promise.resolve(mockSelf)
  }

  var sess: UserSession | undefined = getSession()
  if (sess) {
    const decoded = jwt_decode<JWTIdToken>(sess.token)
    log('decoded id token', decoded)

    const email: UserBase = {
      email: decoded.email,
      name: decoded.name,
      auth0_uid: decoded.sub,
      given_name: decoded?.given_name,
      family_name: decoded?.family_name,
    }

    return Promise.resolve(email)
  }
  return Promise.resolve({ email: '' } as UserBase)
}

export async function JWTFromAuth0v2(auth0DecodedHash: Auth0DecodedHash): Promise<JWTIssuev2> {
  const url = `${buildApiBaseUrl()}/auth`
  log('fetching user auth', { url: url })

  //const body = { idToken: idToken }
  //log('getting JWT via auth0', { url: url, body: body })

  log('JWTFromAuth0v2:', auth0DecodedHash)

  if (configProvider.config.mockEnabled) {
    return Promise.resolve(mockAdminJwt2)
  }

  const jwtIssue = {
    accessToken: auth0DecodedHash.accessToken,
    token: auth0DecodedHash.idToken,
    expiresAt: auth0DecodedHash.expiresIn,
  } as JWTIssuev2
  return jwtIssue
}
