import { ChargersListFormProps } from 'components/form/projects/ChargersListForm'
import { SelfServeChargerConnectivitySchema, SelfServePanelChargerSchema } from 'models/self-serve-commissioning'
import { useState } from 'react'
import { theme } from 'theme'

const useChargerListForm = ({
  initialValues,
  updateInstallation,
}: Omit<ChargersListFormProps, 'panels' | 'connectivity' | 'getChargerConnectivity'>) => {
  const [openAddCharger, setOpenAddCharger] = useState(false)
  const [selectedCharger, setSelectedCharger] = useState<SelfServePanelChargerSchema>()
  const connectivityColors = {
    strong: theme.colors.successPrimary,
    weak: theme.colors.dangerPrimary,
    acceptable: theme.colors.warningPrimary,
    unknown: theme.colors.dangerPrimary,
    offline: theme.colors.dangerPrimary,
  }

  const acceptableMessage = 'Message about they can still proceed now, but don’t be surprised if SWTCH calls them'
  const offlineMessage = 'Ensure the charger is powered on.'
  const weakMessage =
    'Verify RJ45 connections, ensure the Wireless Access Point is within 80ft with a clear line of sight. If the issue still persists, please contact SWTCH Deployment at 1-844-798-2438 ext. 3 for support.'
  const unknownMessage =
    'Charger S/N cannot be found, ensure the S/N has been entered correctly and the charger is powered on.'

  const errorMessages: {
    [K in keyof typeof connectivityColors]: string | undefined
  } = {
    weak: weakMessage,
    offline: offlineMessage,
    unknown: unknownMessage,
    strong: undefined,
    acceptable: undefined,
  }

  const warningMessages = {
    weak: undefined,
    offline: undefined,
    unknown: undefined,
    strong: undefined,
    acceptable: acceptableMessage,
  }

  const convertStatus = (currentConnectivity: SelfServeChargerConnectivitySchema) => {
    if (currentConnectivity.isChargerInCPO) {
      if (currentConnectivity.connectionStatus === 'disconnected') {
        return 'offline'
      } else {
        return currentConnectivity.connectivityStatus
      }
    } else {
      return 'unknown'
    }
  }

  const handleAddCharger = (charger?: SelfServePanelChargerSchema) => () => {
    setSelectedCharger(charger)
    setOpenAddCharger(!openAddCharger)
  }

  const updateCharger = (
    updatedChargerRequest: SelfServePanelChargerSchema,
    existingCharger?: SelfServePanelChargerSchema,
    callBack?: () => void,
  ) => {
    let updatedCharger = [...(initialValues || [])]
    const updatedChargerIndex = updatedCharger?.findIndex(
      (currentPanel) => currentPanel?.serialNumber === existingCharger?.serialNumber,
    )
    if (updatedChargerIndex > -1) {
      updatedCharger[updatedChargerIndex] = {
        ...updatedCharger[updatedChargerIndex],
        ...updatedChargerRequest,
      }
    } else {
      updatedCharger.push(updatedChargerRequest)
    }
    updateInstallation(updatedCharger, callBack)
  }

  const deleteCharger = (panel: SelfServePanelChargerSchema) => () => {
    let updatedCharger = [...(initialValues || [])]
    const updatedChargerIndex = updatedCharger?.findIndex(
      (currentPanel) => currentPanel?.serialNumber === panel?.serialNumber,
    )
    updatedCharger.splice(updatedChargerIndex, 1)
    updateInstallation(updatedCharger)
  }

  const panelModifyOptions = (charger: SelfServePanelChargerSchema) => [
    {
      onClick: handleAddCharger(charger),
      title: 'Edit',
    },
    {
      onClick: deleteCharger(charger),
      title: 'Delete',
    },
  ]

  return {
    offlineMessage,
    weakMessage,
    unknownMessage,
    openAddCharger,
    selectedCharger,
    connectivityColors,
    errorMessages,
    warningMessages,
    convertStatus,
    handleAddCharger,
    updateCharger,
    panelModifyOptions,
  }
}

export default useChargerListForm
