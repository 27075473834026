// Define a function to generate a required rule with a custom message
export const requiredRule = (fieldName: string, photo = false) => ({
  required: true,
  message: photo ? `${fieldName} are required.` : `Please input the ${fieldName}!`,
})

export const noSpecialCharacters = () => ({
  pattern: /^(?!.*\s$)(?!.*\W$)[\w\s-]+(?: [\w\s-]+)*$/,
  message: `Special characters are not allowed!`,
})

export const onlyNumbers = () => ({ pattern: /^[0-9]+$/, message: 'Only numbers are allowed' })

// validate if the input is a number and cannot be over a certain number
export const validateNumberWithMaxLimit = (getFieldValue: any, max: number) => (_: any, value: number) => {
  if (!value || getFieldValue('rated_current') === value) {
    return Promise.resolve()
  }
  if (isNaN(value)) {
    return Promise.reject(new Error('Please enter a valid number'))
  }
  if (value > max) {
    return Promise.reject(new Error(`Value cannot be over ${max}`))
  }
  return Promise.resolve()
}
