import { AddBreakerFormProps } from 'components/form/projects/AddBreakerForm'
import { SelfServeBreakerSchema } from 'models/self-serve-commissioning'
import { useEffect, useState } from 'react'

const useAddBreakerForm = ({ form, handleModalOpen, initialValues, updateInstallation }: AddBreakerFormProps) => {
  const [currentInitialValues, setCurrentInitialValues] = useState<SelfServeBreakerSchema>()
  const [initialValuesLoading, setInitialValuesLoading] = useState(true)

  const typeOfConnectionPhase = [
    {
      value: 'AB',
      label: 'AB',
    },
    {
      value: 'AC',
      label: 'AC',
    },
    {
      value: 'BC',
      label: 'BC',
    },
  ]

  useEffect(() => {
    let loadedInitialValues = {}
    if (initialValues) {
      loadedInitialValues = {
        ...initialValues,
      }
    }
    setCurrentInitialValues(loadedInitialValues)
    setInitialValuesLoading(false)
  }, [initialValues])

  useEffect(() => {
    if (!initialValuesLoading) {
      form.resetFields()
    }
  }, [initialValuesLoading])

  const handleValuesChange = (_: any, allValues: SelfServeBreakerSchema) => {
    setCurrentInitialValues(allValues)
  }

  const onFinish = (values: SelfServeBreakerSchema) => {
    updateInstallation(values, handleModalOpen)
  }

  return { currentInitialValues, typeOfConnectionPhase, handleValuesChange, onFinish }
}

export default useAddBreakerForm
